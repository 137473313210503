import * as React from "react"
import styled from 'styled-components/macro'
import Title from "../../common/Title";
import Subtitle from "../../common/Subtitle";
import Link from "../../common/Link"
import {keyframes} from "styled-components";
import {useState} from "react";
import CoinStack from "../../images/coin-stack.svg";

const ComposableSectionWrapper = styled.div`
  width: 100%;
  color: #f9f9f9;
  padding-bottom: 100px;
  padding-top: 100px;
  background: #f4f4ff;
  color: black;
  
  > div {
    max-width: 1050px;
    margin: 0 auto;
  }
  
  @media screen and (max-width: 880px) {
    > div {
      max-width: 480px;
    }
  }
`

const Content = styled.div`
  width: calc(70% - 75px);
  padding: 25px;
  padding-left: 50px;
  margin: 0 auto;
  display: inline-block;
  vertical-align: top;
  margin-right: 0;
  
  @media screen and (max-width: 1100px) {
    width: calc(65% - 75px);
  }
  
  @media screen and (max-width: 880px) {
    width: calc(100% - 40px);
    padding: 25px 20px;
  }
`

const Graphic = styled.div`
  width: 25%;
  display: inline-block;
  vertical-align: top;
  position: relative;
  height: 180px;
  margin-left: 0;
  
  img {
    margin-top: 18%;
  }
  
  @media screen and (max-width: 1100px) {
    margin-left: 5%;
  }
  
  @media screen and (max-width: 880px) {
    width: 60%;
    margin-left: 20%;
    margin-bottom: 20px;
  }
`

const GraphicImage = styled.div`
  width: 90%;
  position: absolute;
  
  img {
    width: 100%;
  }
  
  :nth-of-type(3) {
    img {
      width: 101%;
      margin-left: -0.5%;
    }
  }
`

const ExchangeAnimation = keyframes`
  from {
    bottom: 26%;
    right: 20%;
    opacity: 0;
  }
  
  4% {
    bottom: 18%;
    right: 12%;
    opacity: 1;
  }
  
  10% {
    bottom: -10%;
    right: 0;
  }
  
  15% {
    bottom: -10%;
    right: 0;
  }
  
  23% {
    bottom: 18%;
    right: 12%;
    opacity: 1;
  }

  32% {
    bottom: 26%;
    right: -5%;
    opacity: 0;
  }
  
  100% {
    bottom: 26%;
    right: -5%;
    opacity: 0;
  }
`

const ShadowAnimation = keyframes`
  /*from {
     filter: drop-shadow( 0 0 0 rgba(0, 0, 0, 1));
  }
  
  45% {
     filter: drop-shadow( 0 0 0 rgba(0, 0, 0, 1));
  }
  
  55% {
    filter: drop-shadow( 14px 26px 3px rgba(0, 0, 0, .2));
  }
  
  65% {
     filter: drop-shadow( 20px 40px 6px rgba(0, 0, 0, .2));
  }
  
  70% {
    filter: drop-shadow( 18px 36px 5px rgba(0, 0, 0, .26));
  }
  
  75% {
    filter: drop-shadow( 20px 40px 6px rgba(0, 0, 0, .2));
  }

  to {
    filter: drop-shadow( 20px 40px 6px rgba(0, 0, 0, .2));
  }*/
`

const ExchangeImage = styled(GraphicImage)`
  z-index: 4;
  bottom: 12%;
  right: 8%;
  
  /*filter: drop-shadow( 0 5px 5px rgba(0, 0, 0, .2)) drop-shadow(0px 8px 10px rgba(0, 0, 0, 0.14)) drop-shadow(0px 3px 14px rgba(0, 0, 0, 0.12));*/
  
  animation-name: ${ExchangeAnimation};
  animation-duration: 15s; 
  animation-timing-function: ease-in-out; 
  animation-delay: 0s;
  animation-direction: normal;
  animation-iteration-count: infinite;
  
  img {
    animation-name: ${ShadowAnimation};
    animation-duration: 5s; 
    animation-timing-function: ease-in-out; 
    animation-delay: 0s;
    animation-direction: normal;
    animation-iteration-count: infinite;
  }
  
  :nth-of-type(2) {
    animation-delay: -5s;
  }
  
  :nth-of-type(3) {
    animation-delay: -10s;
  }
`

const DocumentationImage = styled(GraphicImage)`
  z-index: 3;
  bottom: -10%;
  right: 0;
`

const ComingSoonText = styled.div`
  color: #606375;
  font-size: 15px;
  line-height: 16px;
  margin-top: 12px;
  display: inline-block;
  vertical-align: top;
`

// markup
const ComposableSection = () => {
  const [clickedComingSoon, setClickedComingSoon] = useState(false)

  return (
    <ComposableSectionWrapper>
      <div>
        <Graphic>
          <img src={CoinStack} alt={'More assets graphic'} />
        </Graphic>
        <Content>
          <Title>
            Low-cost, efficient DeFi
          </Title>
          <Subtitle>
            Dolomite is live on Arbitrum, Mantle, Polygon zkEVM, and X Layer. These networks are optimized for high throughput and low gas fees, while preserving user security and composability. Explore Dolomite’s opportunities without the burden of high fees.
          </Subtitle>
          <Link text={'Learn more about our architecture'} dest={'https://docs.dolomite.io/#what-is-capital-efficiency'} breakWidth={410} />
        </Content>
      </div>
    </ComposableSectionWrapper>
  )
}

export default ComposableSection
